<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="优惠券标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入优惠券标题"></a-input>
					</a-form-item>

					<a-form-item label="券类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择券类型" allow-clear style="width: 180px;">
							<a-select-option :value="1">代金券</a-select-option>
							<a-select-option :value="2">兑换券</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="用户手机号" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户手机号"></a-input>
					</a-form-item>

					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<a-form-item label="券用途" name="use" class="ui-form__item">
						<a-select v-model:value="formState.use" placeholder="请选择券用途" allow-clear style="width: 180px;">
							<a-select-option :value="1">影片</a-select-option>
							<a-select-option :value="2">卖品</a-select-option>
							<a-select-option :value="3">商城</a-select-option>
							<a-select-option :value="4">演出</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="使用时间" name="useTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.useTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="24" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 2500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'type'">
							<div v-if="record.type === 1">代金券</div>
							<div v-if="record.type === 2">兑换券</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '' }}</div>
								<div>用户手机号：{{ record.phone || '' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'useLimit'">
							<div v-if="record.useLimit === 1">影片</div>
							<div v-if="record.useLimit === 2">卖品</div>
							<div v-if="record.useLimit === 3">商城</div>
							<div v-if="record.useLimit === 4">演出</div>
						</template>
						<template v-if="column.key === 'useTime'">
							{{ transDateTime(record.useTime) }}
						</template>
						<template v-if="column.key === 'payMessageType'">
							<div>
								<div v-if="record.payMessageType === 'wx_lite' || record.payMessageType === 'zero'">微信</div>
								<div v-else-if="record.payMessageType === 'alipay'">支付宝</div>
								<div v-else-if="record.payMessageType === 'card'">一卡通</div>
								<div v-else>-</div>
							</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
</template>

<script>
	import {
		getUserCouponUsedList
	} from "@/service/modules/coupon";

	export default {
		components: {},
		data() {
			return {
				searchData: [],
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				formState: {

				},
				columns: [{
					title: '券标题',
					dataIndex: 'title',
				}, {
					title: '券类型',
					key: 'type',
				}, {
					title: '券用途',
					key: 'useLimit',
				}, {
					title: '用户信息',
					key: 'userInfo',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
				}, {
					title: '交易流水号',
					dataIndex: 'transactionId'
				}, {
					title: '使用时间',
					key: 'useTime',
				}, {
					title: '商品名称',
					// key: 'goods',
					dataIndex: 'orderGoodsName'
				}, {
					title: '订单金额',
					dataIndex: 'orderTotalFee'
				}, {
					title: '实付金额',
					dataIndex: 'orderPayment'
				}, {
					title: '优惠金额',
					dataIndex: 'deductionPrice'
				}, {
					title: '补差金额',
					dataIndex: 'diffPrice'
				}, {
					title: '支付方式',
					key: 'payMessageType'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				if (this.searchData.useTime && this.searchData.useTime.length === 2) {
					this.searchData.usedStartTime = this.moment(this.formState.useTime[0].startOf('day')).unix();
					this.searchData.usedEndTime = this.moment(this.formState.useTime[1].endOf('day')).unix();
				}
				delete this.searchData.createTime;
				this.loading = true;
				try {
					let ret = await getUserCouponUsedList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>